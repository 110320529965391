import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        padding: '0 !important'
    },
    containerCenter: {
        padding: '0 !important',
        textAlign: 'center'
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.textColor.blue2,
        height: '100vh',
        overflow: 'auto',
    },
    hasilTestBody: {
        width: '80%',
        margin: 'auto',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    hasilTestHead: {
        padding: '10px 0',
    },
    logo: {
        width: 164,
        height: 'auto',
    },
    resultTitle: {
        color: theme.palette.secondary.main,
        fontSize: 40,
        margin: 0,
        lineHeight: '40px',
        textDecoration: 'underline',
    },
    resultSubtitle: {
        color: '#000',
        fontSize: 18,
        margin: '10px 0',
    },
    resultImgWrap: {
        width: 120,
        height: 120,
        overflow: 'hidden',
        borderRadius: '100%',
        border: 'solid 7px $white',
        padding: 5,
        display: 'block',
        margin: '13px auto',
        position: 'relative',
        zIndex: 2,
        background: 'linear-gradient(to bottom, #a4b357 0%,#75890c 100%)',
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            borderRadius: 100,
            border: '7px solid #fff',
        }
    },
    resultImg: {
        width: '100%',
        height: 'auto'
    },
    whiteText: {
        color: '#fff',
        fontSize: 12,
        fontWeight: 700,
        margin: '0 0 5px',
    },
    blackText: {
        color: '#000',
        fontSize: 12,
        fontWeight: 700,
        margin: '0 0 5px',
    },
    announcePerson: {
        backgroundColor: '#fff',
        padding: 5,
        textAlign: 'center',
        width: '85%',
        margin: '0 auto',
        borderRadius: 6,
        textTransform: 'capitalize',
        fontSize: '1.5rem'
    },
    resultContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gap: "10px",
        alignItems: "center",
        maxWidth: 250,
        margin: "0 auto",
    },
    resultTextContainer: {
        textAlign: "left"
    },
    detailBody: {
        marginBottom: 40,
    },
    detailWrap: {
        margin: '20px 0',
    },
    heading: {
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 'bold',
    },
    panelDetail: {
        fontSize: 12,
        textAlign: 'left',
    },
    panelDetailCapital: {
        textTransform: 'capitalize',
    },
    dividerBtn: {
        position: 'relative',
        height: 40,
        overflow: 'visible',
        marginBottom: 10,
    },
    line: {
        width: '100%',
        height: 3,
        backgroundColor: theme.palette.secondary.main,
        position: 'absolute',
        top: 5,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 1,
    },
    detailTrigger: {
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        position: 'relative',
        zIndex: 2,
        padding: '6px 12px',
        borderRadius: 25,
        margin: '0 auto',
        width: 180,
        display: 'block',
        border: 'solid 7px' + theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    downarrow: {
        width: '10px',
        height: '10px',
        verticalAlign: 'middle',
        transform: 'translateX(9px)'
    },
    saveBtn: {
        position: 'fixed',
        top: 67,
        left: 10,
        color: '#222',
        borderRadius: "50%",
        backgroundColor: "#81e0ff"
    },
    botMsg: {
        backgroundColor: theme.palette.secondary.main,
        paddingBottom: 60,
    }
}))

export default useStyles;