import PropTypes from "prop-types";
import React from "react";
import dataKampus from "./data/universitas.json";
import AppSelect from "./index";

function SelectKampus({ TextFieldProps = {}, ...props }) {
  return (
    <AppSelect
      disabled={TextFieldProps.disabled}
      options={
        dataKampus
          ? dataKampus.map(kampus => ({
              label: kampus.nama,
              value: kampus.ptn_id,
            }))
          : []
      }
      inputId="react-select-kampus"
      placeholder="Pilih Kampus Impian"
      TextFieldProps={{
        label: "Kampus Impian",
        InputLabelProps: {
          htmlFor: "react-select-kampus",
          shrink: true,
        },
        ...TextFieldProps,
      }}
      {...props}
    />
  );
}

SelectKampus.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectKampus;
