import React, { useState } from 'react';

// import komponen material ui 
import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// import komponen React Router DOM
import { Link } from 'react-router-dom';

// import styles
import useStyles from './styles';
import pbStyles from './pakbudi.css';
import { baseUrl, logo } from '../../images/s3';
import pbUserIcon from '../../images/users.png';
import newLogo from '../../images/new-logo-w.png';
import decorBottom from '../../images/decor-bottom.png';

function Home(props) {
    const classes = useStyles();
    const [showPopup, setShowPopup] = useState(true);
    const [notStudent, setNotStudent] = useState(false);
    return (
        <Container maxWidth="xl" component="main" className={classes.mainWrap}>
            <div className={classes.mainBody}>
                <div className={classes.logoWrap}>
                    <img className={classes.logo} src={logo} alt="logo" />
                </div>
                <Typography className={classes.heroTitle} variant="h1">87%</Typography>
                <Typography className={classes.subtitle} variant="h3">Orang Salah Pilih Jurusan Kuliah !!</Typography>
                <Typography className={classes.text} variant="subtitle1">Ikuti Tes Minat Bakat Online</Typography>
                <Typography className={classes.free} variant="h4">GRATIS!!</Typography>
                <Typography className={classes.text} variant="subtitle1">Agar kamu bisa tau jurusan kuliah dan jenis pekerjaan yang sesuai dengan bakatmu:</Typography>
                <div className={classes.btnWrap}>
                    <Button className={classes.btnTest} size="large" component={Link} to="/game" variant="contained" color="primary">ISI TEST SEKARANG</Button>
                </div>
                <div className={classes.botSection}>
                    <img className={classes.heroImg} src={`${baseUrl}chars-all.png`} alt="logo" />
                </div>
            </div>
            {showPopup && (
                <div className="pb-popup-wrapper">
                    <div className="pb-popup-container">
                        <img src={pbUserIcon} alt="user-icon" />
                        <h2>Siapa Kamu?</h2>
                        <button className="pb-button" onClick={() => { setShowPopup(false) }}>Saya Pelajar SD/SMP/SMA</button>
                        <button className="pb-button" onClick={() => { setNotStudent(true) }}>Saya Bukan Pelajar</button>
                    </div>
                </div>
            )
            }
            <div className={`pb-slider-page ${notStudent ? 'active' : ''}`}>
                <div className="pb-slider-page-header">
                    <div className="pb-page-wrapper">
                        <img src={newLogo} />
                    </div>
                </div>
                <div className="pb-page-wrapper">
                    <div className="pb-slider-page-container">
                        <h2>"Semua orang lahir dengan keunikannya masing-masing"</h2>
                        <p>Cari tahu bakat dan kepribadianmu, dan juga bagaimana kamu bisa menjadi pribadi yang lebih baik kedepannya dengan tes singkat hanya 6 menit.</p>
                        <p>(Spoiler: ada info tentang kecocokan pasangan juga lho)</p>
                        <Link to="/game">
                            <button className='pb-primary-button'>Ikuti Tes!</button>
                        </Link>
                    </div>
                </div>
                <div className="pb-page-wrapper">
                    <div className="pb-footer-container">
                        <img className='pb-decor-image' src={decorBottom} />
                    </div>
                </div>
            </div>
            }
        </Container >
    );
}

export default Home;