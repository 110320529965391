import PropTypes from "prop-types";
import React from "react";
import useRest from "../../hooks/useRest";
import AppSelect from "./index";
function SelectJurusan({ TextFieldProps = {}, ptn_id, ...props }) {
  const [response, loading] = useRest({
    url: "jurusan",
    method: "get",
    params: {
      ptn_id,
    },
  });

  return <AppSelect
      disabled={loading || !ptn_id || TextFieldProps.disabled}
      options={
        response
          ? response.data.Items.map(jurusan => ({
              label: jurusan.nama,
              value: jurusan.jurusan_id,
            }))
          : []
      }
      inputId="react-select-jurusan"
      placeholder="Pilih Jurusan Impian"
      TextFieldProps={{
        label: "Jurusan Impian",
        InputLabelProps: {
          htmlFor: "react-select-jurusan",
          shrink: true,
        },
        ...TextFieldProps,
        disabled: loading || !ptn_id || TextFieldProps.disabled,
      }}
      {...props}
      loading={loading}
    />
}

SelectJurusan.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  ptn_id: PropTypes.string,
};

export default SelectJurusan;
